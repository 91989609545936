// Root.tsx defines sidebar functionality.

import React, {PropsWithChildren} from "react";
import {makeStyles} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import CreateComponentIcon from "@material-ui/icons/AddCircleOutline";
import FireplaceIcon from "@material-ui/icons/Fireplace";
import DashboardTwoTone from "@material-ui/icons/DashboardTwoTone";
import LogoFull from "./LogoFull";
import LogoIcon from "./LogoIcon";
import {MyGroupsSidebarItem} from "@backstage/plugin-org";
import GroupIcon from "@material-ui/icons/People";
import {Settings as SidebarSettings, UserSettingsSignInAvatar,} from "@backstage/plugin-user-settings";
import {SidebarSearchModal} from "@backstage/plugin-search";
import {
    CatalogIcon,
    ChatIcon,
    Link,
    Sidebar,
    sidebarConfig,
    SidebarDivider,
    SidebarGroup,
    SidebarItem,
    SidebarPage,
    SidebarScrollWrapper,
    SidebarSpace,
    useSidebarOpenState,
} from "@backstage/core-components";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import {RBACSidebarItem} from "@spotify/backstage-plugin-rbac";


import { Metadata } from "@internal/misc";

const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "center",
        marginBottom: -14,
    },
    link: {
        width: sidebarConfig.drawerWidthClosed,
        marginLeft: 24,
    },
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const {isOpen} = useSidebarOpenState();

    return (
        <div className={classes.root}>
            <Link to="/" underline="none" className={classes.link} aria-label="Home">
                {isOpen ? <LogoFull/> : <LogoIcon/>}
            </Link>
        </div>
    );
};

export const Root = ({children}: PropsWithChildren<unknown>) => (
    <SidebarPage>
        <Sidebar>
            <SidebarLogo/>
            <Metadata/>
            <SidebarDivider/>
            <SidebarGroup label="Search" icon={<SearchIcon/>} to="/search">
                <SidebarSearchModal/>
            </SidebarGroup>
            <SidebarDivider/>
            <SidebarGroup label="Menu" icon={<MenuIcon/>}>
                {/* Global nav, not org-specific */}
                <SidebarItem icon={HomeIcon} to="" text="Home"/>
                <SidebarItem icon={CatalogIcon} to="catalog" text="Catalog"/>
                <SidebarItem icon={LibraryBooks} to="docs" text="Docs"/>
                <SidebarItem icon={DoneAllIcon} to="soundcheck" text="Soundcheck"/>
                <SidebarItem icon={ChatIcon} to="secret-sharing" text="Secret Sharing"/>
                <SidebarItem icon={FireplaceIcon} to="firechief" text="FireChief"/>
                <SidebarItem icon={DashboardTwoTone} to="k8s-dashboard" text="K8s Dashboard"/>
                <SidebarItem icon={CreateComponentIcon} to="create" text="Templates"/>
                {/* End global nav */}
                <SidebarDivider/>
                <SidebarScrollWrapper>
                    <MyGroupsSidebarItem
                        singularTitle="My Group"
                        pluralTitle="My Groups"
                        icon={GroupIcon}
                    />
                    <RBACSidebarItem/>
                </SidebarScrollWrapper>
            </SidebarGroup>
            <SidebarSpace/>
            <SidebarDivider/>
            <SidebarGroup
                label="Settings"
                icon={<UserSettingsSignInAvatar/>}
                to="/settings"
            >
                <SidebarSettings/>
            </SidebarGroup>
        </Sidebar>
        {children}
    </SidebarPage>
);
